<script lang="ts" setup>
import type { ILeaseSummaryField, ILeaseSummaryFieldValue } from '@register'
import { createColumnHelper } from '@tanstack/vue-table'
import { useTanstackTableVariants } from '@ui/components/TanstackTable'

type OptionExerciseDetailsTableValue = {
  option: number | undefined
  exerciseStartDate: string
  exerciseEndDate: string
  isExercised: boolean
}
interface Props {
  field: ILeaseSummaryField
  value: ILeaseSummaryFieldValue
}
const props = defineProps<Props>()

const currentValue = computed(() => {
  return props.value?.value ?? props.field.value?.value
})

const dayjs = useDayjs()
const { dateFormat: divisionDateFormat } = useDivisions()
const { dateFormat: leaseDateFormat } = useCurrentLease()

// TABLE HEADER
const columnHelper = createColumnHelper<OptionExerciseDetailsTableValue>()
const columns = [
  columnHelper.accessor('option', {
    header: 'Option',
  }),
  columnHelper.accessor('exerciseStartDate', {
    header: 'Exercise Start Date',
  }),
  columnHelper.accessor('exerciseEndDate', {
    header: 'Exercise End Date',
  }),
  columnHelper.accessor('isExercised', {
    header: 'Exercised',
  }),
]

// TABLE ITEMS
const tableValues = computed<OptionExerciseDetailsTableValue[]>(() => {
  if (!Array.isArray(currentValue?.value)) return []

  return currentValue?.value?.map((item) => {
    return {
      option: item.option,
      isExercised: item.isExercised,
      exerciseStartDate: item.exerciseStartDate
        ? dayjs(item.exerciseStartDate, divisionDateFormat.value).format(
            leaseDateFormat.value,
          )
        : 'Not defined',
      exerciseEndDate: item.exerciseEndDate
        ? dayjs(item.exerciseEndDate, divisionDateFormat.value).format(
            leaseDateFormat.value,
          )
        : 'Not defined',
    }
  })
})

const items = computed(() => {
  if (!tableValues.value) {
    return []
  }

  return [...tableValues.value].sort((a, b) => {
    if (!a || !b) return 0

    // Convert the option values to numbers before comparison
    const optionA = parseInt(String(a), 10)
    const optionB = parseInt(String(b), 10)

    return optionB - optionA

    // return b.option > a.option ? -1 : 1
  })
})

const variant = useTanstackTableVariants({
  slots: {
    base: 'mb-2 overflow-hidden rounded-sm text-sm -mx-4',
    thead: 'border-b-transparent',
    theadTr: 'bg-transparent h-auto',
    theadTh: 'text-xs leading-none text-gray-400 font-normal',
    tbody: 'divide-transparent',
    tbodyTr: 'h-auto',
    tbodyTd: 'text-xs leading-none text-white',
  },
  variants: {
    stripedRows: {
      false: {
        tbodyTr: 'bg-transparent',
      },
    },
    padding: {
      md: {
        theadTh: 'py-3',
        tbodyTd: 'py-3',
      },
    },
  },
})
</script>

<template>
  <span v-if="tableValues.length === 0"> N/A </span>

  <div v-else>
    <TanstackTable
      :data="items"
      :columns="columns"
      aria-describedby="Option exercise details"
      :hoverable="false"
      :variant="variant"
    >
      <template #item-exerciseStartDate="obj">
        <time>{{ obj.value }}</time>
      </template>

      <template #item-exerciseEndDate="obj">
        <time>{{ obj.value }}</time>
      </template>

      <template #item-isExercised="obj">
        <span
          v-if="obj.value"
          class="bg-primary relative ml-4 flex h-6 w-6 items-center justify-center self-center rounded-full"
        >
          <Icon name="check" class="text-black" />
        </span>
        <span
          v-else
          class="bg-gray-750 relative ml-4 flex h-6 w-6 items-center justify-center self-center rounded-full"
        >
          -
        </span>
      </template>
    </TanstackTable>
  </div>
</template>
